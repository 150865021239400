import { SEO } from "@components/seo";
import { globalHistory as history } from '@reach/router';
import React from "react";
import Container from "react-bootstrap/Container";
import { isMobile } from "react-device-detect";
import Cursor from "../components/Cursor/cursor";
import Layout from "../components/layout";
// import TonyeAwards from "../components/_pages/home/TonyeAwards";
import TonyeBio from "../components/_pages/home/TonyeBio";
import Section from "../components/_SectionTemplate";
// import FeaturedWork from "./../components/_pages/home/FeaturedWork";
import { TonyeLoves } from './../components/TonyeLoves';
import "./../styles/pages/_about.scss";


const AboutPage = (props) => {

  const { location } = history;

  const isHomePage = location.pathname === "/";

  return <Layout location={location}>
    {!isMobile && <Cursor />}
    {/* <Scroll callbacks={location} /> */}
    <SEO title="My Portfolio" />

    <Container fluid>
      <Section desc="about">
        <div
          id="about"
          className="about"
          data-scroll-section
          data-scroll-target="#home"
          data-scroll
          data-scroll-speed="5"
        >

          <div className="position-relative about-wrapper" data-scroll-target="#about" >
            <TonyeBio location={location} />
          </div>
          {false && <TonyeLoves />}
          {/* <TonyeAwards /> */}
          <div className="position-relative about-wrapper featured" data-scroll-target="#about" data-scroll data-scroll-speed="0.1">
            {/* <FeaturedWork /> */}
          </div>
          {/* <TonyeMarquee content="I'm super passionate about everything creative. Technology, music, fitness, art & culture." /> */}
        </div>
      </Section>
    </Container>
  </Layout>
}

export default AboutPage;